@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700;800&family=Spectral:wght@400;700&display=swap');
@import './_variables.scss';

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	color: $darkest;
	font-family: $fontBody;
	font-size: 110%;
	line-height: 1.75;
	margin: 0;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

button {
	background-color: transparent;
	border: none;
	padding: 0;
}

small {
	font-size: 75%;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $fontHeading;
	line-height: 1.2;
	margin-bottom: $unit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
address {
	margin-bottom: $unit;
	margin-top: 0;
}

h1,
.h1 {
	font-size: 46px;
}

h2,
.h2 {
	font-size: 36px;
}

h3,
.h3 {
	font-size: 24px;
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 h6,
.h6 {
	font-size: 16px;
}

h6,
.h6 {
	font-family: $fontBody;
	font-weight: normal;
}

input,
select,
textarea {
	font-family: $fontBody;
	outline: none;
}

figure {
    margin: 0;
}

button,
a {
	cursor: pointer;
}

button {
	font-family: $fontHeading;
}

ul,
ol {
	list-style: none;
	padding-left: 0;
}

address {
	font-style: initial;
}

blockquote {
	margin: 0;
}

::selection {
	background: $secondary;
	/* WebKit/Blink Browsers */
}

::-moz-selection {
	background: $secondary;
	/* Gecko Browsers */
}

.container {
	&+& {
		margin-top: $unit * 2;

		@media screen and (min-width: 768px) {
			margin-top: $unit * 4;
		}
	}

	&> :last-child {
		margin-bottom: 0;
	}
}

.wrapper {
	padding: $unit*2;
	width: 100%;

	@media screen and (min-width: 768px) {
		padding-bottom: $unit * 3;
		padding-top: $unit * 3;
	}

	@media screen and (min-width: 1200px) {
		padding-bottom: $unit * 4;
		padding-top: $unit * 4;
	}

	&:first-of-type {
		padding-top: $unit * 6;

		@media screen and (min-width: 768px) {
			padding-top: $unit * 8;
		}

		@media screen and (min-width: 1200px) {
			padding-top: $unit * 10;
		}
	}
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width: calc(75rem - $unit*2); // 1200px
	width: 100%;
}


.bg-primary {
	background-color: $primary;
}

.bg-white {
	background-color: $white;
}

.bg-darkest {
	background-color: $darkest;
}

.bg-dark {
	background-color: $dark;
}

.bg-neutral {
	background-color: $primaryLight;
}

.color-primary {
	color: $primary;
}

.color-secondary {
	color: $primaryDark;
}

.color-white {
	color: #fff;
}

.color-neutral {
	color: $primaryLight;
}

.color-secondary {
	color: $secondary;
}

.btn {
	align-items: center;
	background-color: $primary;
	border-radius: $radius;
	color: $white;
	font-family: $fontHeading;
	font-weight: 800;
	border: 2px solid;
	border-color: $primary;
	display: inline-flex;
	padding-bottom: .925rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: auto;

	&:focus,
	&:hover {
		background-color: $primaryDark;
		border-color: $primaryDark;
	}
}

.btn-small {
	padding-bottom: .2rem;
	padding-left: .35rem;
	padding-right: .35rem;
	padding-top: .3rem;
}

.btn-white {
	border-color: $white;
	color: $white;

	&:focus,
	&:hover {
		background-color: transparent;
		border-color: $primaryDark;
		color: $primaryDark;
	}
}

.btn-icon {
	align-items: center;
	display: flex;
	height: 24px;
	justify-content: center;
	margin-left: .5rem;
	width: 24px;

	svg {
		fill: $white;
		height: inherit;
		width: inherit;
	}

	.btn-white:focus & svg,
	.btn-white:hover & svg {
		fill: $primaryDark;
	}
}

.zebra-stripe {
	& > :nth-of-type(odd) {
		background-color: $primary;
		color: $white;

		.h2 {
			color: $white;
		}
	}

	& > :nth-of-type(even) {
		.h2 {
			color: $primary;
		}
	}
}

.font-body {
	font-family: $fontBody;
}

.font-heading {
	font-family: $fontHeading;
}

.app-header {
	background-color: transparent;
	height: 50px;
	position: fixed;
	transform: translateY(0%);
	transition:
		background-color 125ms ease-in,
		transform 125ms ease-in;
	width: 100%;
	z-index: 1;

	@media screen and (min-width: 960px) {
		height: 80px;
	}

	&:before {
		box-shadow: 0 0 4px rgba(0, 0, 0, .5);
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		transition: opacity 250ms ease-in;
		opacity: 0;
	}

	&.header-is-engaged {
		background-color: $primary;
		transform: translateY(-100%);
		transition:
			background-color 500ms ease-out,
			transform 500ms ease-out;
	}

	&.header-is-engaged.header-is-active {
		transform: translateY(0%);
		transition: transform 250ms ease-out;
	}

	&.header-is-engaged.header-is-active:before {
		transition: opacity 1000ms ease-out;
		opacity: 1;
	}
}

.app-header-inner {
	align-items: center;
	display: flex;
	height: inherit;
	justify-content: space-between;
	overflow: hidden;
	padding-left: $unit * 2;
	padding-right: $unit * 2;

	@media screen and (min-width: 960px) {
		margin-left: auto;
		margin-right: auto;
		max-width: calc(1200px + $unit*2);
	}
}

.app-logo {
	display: flex;
	flex-direction: column;
	position: relative;
	text-decoration: none;
	z-index: 1;

	@media screen and (min-width: 960px) {
		align-items: center;
		flex-direction: row;
		width: auto;
	}

	svg {
		display: block;
		height: 100%;
	}

	.header-is-engaged & svg {
		fill: $white;
	}
}

.app-logo-icon {
	width: 30px;

	@media screen and (min-width: 960px) {
		width: 42px;
	}

	svg {
		fill: $primary;
		transition: fill 100ms ease-in-out;

		[data-page="/"] &,
		[data-page="/about"] & {
			fill: $white;
		}
	}

	.header-is-engaged .app-logo:hover & svg {
		fill: $primaryDark;
	}
}

.app-title {
    margin-bottom: 0;
}

.app-logo-slogan {
	color: $white;
	display: none;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;

	@media screen and (min-width: 960px) {
		color: $primary;
		display: block;
	}

	[data-page="/"] &,
	[data-page="/about"] &,
	.header-is-engaged & {
		@media screen and (min-width: 768px) {
			color: $white
		}
	}

	.app-logo-icon + & {
		margin-left: $unit;
	}
}

.menu {
	height: 23px;
	position: relative;
	right: 0;
	top: 0;
	width: 30px;
	z-index: 99;

	@media screen and (min-width: 960px) {
		display: none;
	}
}

.menu-bar {
	background-color: $darkest;
	display: block;
	height: 2px;
	position: absolute;
	width: 100%;

	[data-page="/"] &,
	[data-page="/about"] & {
		background-color: $white;
	}

	.nav-is-active &,
	.header-is-engaged & {
		background-color: $white;
	}

	&:nth-child(1) {
		transform: translateY(-4px) rotate(0deg);
	}

	.nav-is-active &:nth-child(1) {
		transform: translateY(0px) rotate(45deg);
	}

	&:nth-child(2) {
		transform: translateY(0px) rotate(0deg);
	}

	.nav-is-active &:nth-child(2) {
		display: none;
	}

	&:nth-child(3) {
		transform: translateY(4px) rotate(0deg);
	}

	.nav-is-active &:nth-child(3) {
		transform: translateY(0px) rotate(-45deg);
	}
}

.nav {
	align-items: center;
	background-color: $darkest;
	display: flex;
	flex-direction: column;
	font-family: $fontHeading;
	left: 0;
	padding: $unit * 2;
	position: absolute;
	height: 92vh;
	top: 0;
	transform: translateX(0) translateY(-110%);
	transition: transform 100ms ease-in;
	width: 100vw;
	z-index: 98;

	@media screen and (min-width: 960px) {
		background-color: transparent;
		display: block;
		height: auto;
		left: unset;
		padding: 0;
		position: relative;
		top: unset;
		transform: unset;
		transition: none;
		width: 100%;
	}

	.nav-is-active & {
		box-shadow: 0px 20px 50px rgb(0 0 0);
		transform: translateY(0);
		transition: transform 200ms ease-out;

		@media screen and (min-width: 960px) {
			box-shadow: none;
			transform: none;
		}
	}
}

.nav-logo {
	align-items: center;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	text-transform: uppercase;

	@media screen and (min-width: 960px) {
		display: none;
	}
}

.nav-icon {
	width: 120px;

	@media screen and (min-width: 720px) {
		width: 100px;
	}

	svg {
		fill: $primary;
	}
}

.nav-slogan {
	display: inline-block;
	font-size: 18px;

	.nav-icon+& {
		margin-top: 8px;
	}
}

.nav-list {
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 1.5rem;
	justify-content: space-between;
	text-align: center;
	text-transform: uppercase;

	@media screen and (min-width: 960px) {
		flex-direction: row;
		font-size: $unit;
		font-weight: bold;
		justify-content: flex-end;
		text-align: right;
	}

	@media screen and (min-width: 1200px) {
		font-size: 0.875rem; //14px
	}

	.nav-logo+& {
		margin-top: $unit;

		@media screen and (min-width: 960px) {
			margin-top: 0;
		}
	}
}

.nav-item {
	overflow: hidden;

	& + & {
		@media screen and (min-width: 960px) {
			margin-left: 10px;
		}
	}

	&:last-of-type {
		margin-top: $unit;

		@media screen and (min-width: 960px) {
			margin-top: 0;
		}
	}
}

.nav-link {
	color: $white;
	font-weight: 700;
	position: relative;
	text-decoration: none;

	@media screen and (min-width: 960px) {
		color: $darkest;
	}

	[data-page="/"] &,
	[data-page="/about"] &,
	.header-is-engaged & {
		@media screen and (min-width: 960px) {
			color: $white;
		}
	}

	&:focus,
	&:hover {
		@media screen and (max-width: 960px) {
			color: $secondary;
		}
	}

	&:before {
		background-color: $primaryDark;
		border-radius: 2px;
		bottom: 0;
		content: '';
		height: 2px;
		position: absolute;
		transform: translateX(-110%);
		transition: 100ms ease-in transform;
		width: calc(100% - $unit);
	}

	.header-is-engaged &:before {
		background-color: $primaryDark;
	}

	&:hover:before,
	&.nav-link-is-active:before {
		transform: translateX(0%);
		transition: 250ms ease-out transform;
	}
}

.nav-link-cta {
	background-color: $primary;
	border-color: $primary;
	color: $white;
	font: inherit;

	&:focus,
	&:hover {
		background-color: $secondary;
		border-color: $secondary;
		color: $primaryDark;
	}

	&:before {
		display: none;
	}

	.header-is-engaged & {
		background-color: $secondary;
		color: $primaryDark;
	}

	.header-is-engaged &:focus,
	.header-is-engaged &:hover {
		border-color: $dark;
	}
}

.nav-is-active {
	@media screen and (max-width: 960px) {
		overflow-y: scroll;
		position: fixed;
	}
}

.app-main {
	min-height: 75vh;
	overflow: hidden;
}

.app-footer {
	background-color: $white;
	border-bottom: 8px solid $primary;
	color: $white;
	position: relative;
	z-index: 0;

	&:after {
		border-bottom: 4px solid $primaryDark;
		display: block;
		content: '';
	}

	.app-main + & {
		margin-top: $unit * 4;
	}
}

.app-footer-body {
	background-color: $darkest;
	box-shadow: inset 0 -7px 20px 0px rgba(0,0,0,.25);
	min-height: calc(100vh - 12px);
}

.footer-columns {
	@media screen and (min-width: 768px) {
		align-items: flex-start;
		display: flex;
		width: 100%;
	}
}

.footer-column {
	& + &:not(#footer-cta) {
		margin-top: $unit * 3;

		@media screen and (min-width: 768px) {
			margin-left: $unit * 2;
			margin-top: 0;
		}

		@media screen and (min-width: 960px) {
			margin-left: $unit * 3.25;
		}
	}
}

.footer-column-bio {
	& .footer-column + .footer-column {
		@media screen and (min-width: 768px) {
			margin-left: $unit * 1.5;
		}
	}
}

.footer-logo {
	display: inline-block;
	height: 76px;
	margin-bottom: $unit;
	position: relative;

	@media screen and (min-width: 768px) {
		transform: translateY(-25%);
	}

	svg {
		display: block;
		fill: $white;
		height: 100%;
		position: relative;
		z-index: 1;
	}
}

.footer-logo-pulse {
	background-color: $primary;
	height: 12px;
	width: 12px;
	right: 17px;
	border-radius: 100%;
	top: 8px;
	display: block;
	position: absolute;
	z-index: 0;

	&:after {
		animation: pulsate 3000ms infinite cubic-bezier(0.62, 0.41, 0.08, 0.91);
		content: '';
		background-color: $secondary;
		border-radius: 100%;
		content: '';
		height: 12px;
		left: 50%;
		margin-left: -6px;
		position: absolute;
		top: 50%;
		margin-top: -6px;
		width: 12px;
	}
}

@keyframes pulsate {
	100% {
	  opacity: 0;
	  transform: scale(5);
	}
  }

.footer-link {
	color: $secondary;
	text-decoration: none;

	&:focus,
	&:hover {
		color: $primaryLight;
	}
}

.footer-subtitle {
	margin-bottom: 10px;
	text-transform: uppercase;
}

.footer-divider {
	&+& {
		border-top: 1px solid;
		border-color: $dark;
		margin-top: 10px;
		padding-top: 10px;
	}
}

.footer-group {
	display: flex;

	&> :last-child {
		margin-left: $unit *2;
	}
}

.footer-legal {
	color: $gray;
	display: block;
	font-family: $fontHeading;

	.footer-columns+& {
		margin-top: $unit * 3;
	}
}

.inline-list {
	li {
		display: inline-block;
	}
}

.partner-list {
	align-items: center;
	display: grid;
	grid-auto-rows: auto;
	grid-gap: $unit * 2;
	grid-template-columns: repeat(3, 1fr);
	margin-top: $unit * 2;

	@media screen and (min-width: 768px) {
		grid-gap: $unit * 4;
		grid-template-columns: repeat(4, 1fr);
		margin-top: $unit * 4;
	}

	@media screen and (min-width: 1200px) {
		grid-gap: $unit*4 $unit*6;
	}
}

.partner-img {
	display: block;
	margin: auto;
	max-width: 150px;
	width: 100%;

	@media screen and (min-width: 768px) {
		max-width: 180px;
	}
}

.social-list {
	align-items: center;
	display: flex;
}

.social-item {
	width: 20px;

	& + & {
		margin-left: $unit/2;
	}

	svg {
		fill: $secondary;
		display: block;
		height: 100%;
	}
}

.social-link {

	&:focus svg,
	&:hover svg {
		fill: $primaryLight;
	}
}

.fancy-list {
	list-style-type: square;
	padding-left: 20px;

	li+li {
		margin-top: $unit;
	}
}

.jumbotron {
	display: flex;
	overflow: hidden;
	position: relative;
}

.jumbotron-bg {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	height: 100%;
	width: 100%;
}

.jumbotron-video {
	background-color: inherit;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.jumbotron-vignette {
	background-color: rgba(0, 0, 0, .5);
	background: radial-gradient(100% 500%, transparent, rgba(0, 0, 0, .15) 100%);
	height: 100%;
	position: absolute;
	width: 100%;
}

.jumbotron-body {
	color: $white;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: $unit * 2;
	position: relative;
	width: 100%;

	@media screen and (min-width: 768px) {
		align-items: flex-end;
		display: flex;
		min-height: 50vh;
	}

	@media screen and (min-width: 1200px) {
		min-height: 82vh;
		padding-bottom: $unit * 6;
		padding-top: $unit * 6;
	}

	[data-page="/"] &,
	[data-page="/about"] & {
		padding-top: $unit * 6;
	}
}

.jumbotrom-img {
	display: block;
	width: 40px;

	svg {
		fill: $white;
	}
}

.jumbotron-fancy-img {
	width: 100%;
	height: 100%;
	position: absolute;
	max-width: 80%;
	right: 0;

	@media screen and (min-width: 768px) {
		max-width: 50%;
	}

	svg {
		display: block;
		fill: rgba(0, 0, 0, .2);
	}
}

.jumbotron-title {
	font-size: $unit * 2;
	margin-bottom: 0;
	position: relative;

	@media screen and (min-width: 768px) {
		font-size: $unit * 3;
	}

	@media screen and (min-width: 1200px) {
		font-size: $unit * 4;
		width: 75%;
	}
}

.jumbotron-info {
	font-family: $fontBody;
	font-size: calc(20px / 16px * 1rem);

	@media screen and (min-width: 768px) {
		width: 320px;
	}

	.jumbotron-title+& {
		margin-top: $unit;
	}
}

.jumbotron-large-info {
	@media screen and (min-width: 768px) {
		width: 600px;
	}
}

.billboard {
	position: relative;
}

.billboard--fancy {
	background-position: 30% 0%;
	background-repeat: no-repeat;
	background-size: cover;

	@media screen and (min-width: 768px) {
		background-position: bottom 20% left 180px;
	}

	@media screen and (min-width: 960px) {
		background-position: bottom 50% left 240px;
	}
}

.billboard-vignette {
	background: linear-gradient(to bottom right, rgba(255, 255, 255, 1) 35%, transparent);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	@media screen and (min-width: 768px) {
		background: linear-gradient(to right, $white 50%, transparent);
	}
}

.billboard-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	@media screen and (min-width: 768px) {
		min-height: 50vh;
		width: 50%;
	}
}

.bio {
	color: $white;
	// min-height: 80vh;
}

.bio-section {
	margin: auto;
	padding-left: 10%;
	padding-right: 10%;
	position: relative;
	z-index: 0;

	&:first-of-type {
		color: $white;
		padding-top: 10vh;

		@media screen and (min-width: 768px) {
			padding-top: 20vh;
		}
	}

	&:first-of-type+& {
		color: $darkest;
		padding-top: 10vh;

		@media screen and (min-width: 768px) {
			padding-top: 25vh;
		}
	}

	&:last-of-type {
		padding-bottom: $unit * 2;

		@media screen and (min-width: 768px) {
			padding-bottom: $unit*3;
		}

		@media screen and (min-width: 1200px) {
			padding-bottom: $unit*4;
		}
	}
}

.bio-inner {
	margin: auto;
	width: 75%;
}

.bio-arrow {
	position: absolute;
	left: 50%;
	top: 90%;
	z-index: 0;
	height: 0;
	width: 0;
	border-left: 100vw solid transparent;
	border-right: 100vw solid transparent;
	border-top: 95px solid $primary;
	transform: translateX(-50%);

	@media screen and (min-width: 768px) {
		border-top: 160px solid $primary;
		top: 100%;
	}
}

.bio-inner {
	position: relative;
	z-index: 1;
}

.bio-title {
	font-size: 4vw;
	line-height: 1;

	@media screen and (min-width: 768px) {
		font-size: $unit * 5;
	}

	@media screen and (min-width: 960px) {
		font-size: $unit * 7;
		max-width: 1100px;
	}

	span {
		display: block;
	}
}

.bio-subtitle {
	font-size: $unit * 2;
	line-height: 1;

	@media screen and (min-width: 960px) {
		font-size: $unit * 3;
	}
}

.bio-info {
	font-size: $unit;

	@media screen and (min-width: 768px) {
		font-size: $unit * 2;
	}
}

.cards {
	display: grid;
	grid-auto-rows: auto;
	grid-gap: 1rem;

	@media screen and (min-width: 600px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 768px) {
		grid-gap: 4rem;
		grid-template-columns: repeat(3, 1fr);
	}
}

.card {
	display: inline-block;
	text-align: left;
	text-decoration: inherit;

	&.card--fancy {
		color: $white;
	}

	:only-child {
		margin-bottom: 0;
	}
}

.card-title {
	font-family: $fontBody;
	font-weight: 400;
}

.article {
	@media screen and (min-width: 512px) {
		align-items: flex-start;
		display: flex;
	}

	& + & {
		border-top: 1px solid;
		border-top-color: $light;
		margin-top: $unit * 2;
		padding-top: $unit * 2;

		@media screen and (min-width: 960px) {
			margin-top: $unit * 3;
			padding-top: $unit * 3;
		}
	}
}

.article-img {
	@media screen and (min-width: 512px) {
		min-width: 180px;
		width: 15%;
	}

	@media screen and (min-width: 768px) {
		min-width: 260px;
		width: 15%;
	}

	img {
		max-width: 100%;
	}
}

.article-body {
	margin-top: $unit;

	.article-img+& {
		@media screen and (min-width: 512px) {
			margin-left: $unit * 2;
			margin-top: 0;
			width: 75%;
		}
	}
}

.article-timestamp {
	font-size: 70%;
}

.article-title {
	a {
		color: inherit;
		text-decoration: none;
	}
}

.article-info {
	&:last-child {
		margin-bottom: 0;
	}
}

// .article-link {
// 	text-decoration: none;

// 	&:focus span,
// 	&:hover span {
// 		border-bottom: 1px solid $primary;
// 	}
// }

.text-uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.em {
	font-style: italic;
}

.d-none {
	display: none;
}

// Margin bottom
.mb-0 {
	margin-bottom: 0;
}

@for $i from 1 through 5 {
	.mb-#{$i} {
		margin-bottom: $unit * $i;
	}
}

// Margin top
@for $i from 1 through 5 {
	.mt-#{$i} {
		margin-top: $unit * $i;
	}
}

// Margin right
@for $i from 1 through 5 {
	.mr-#{$i} {
		margin-right: $unit * $i;
	}
}

// Margin left
@for $i from 1 through 5 {
	.ml-#{$i} {
		margin-left: $unit * $i;
	}
}

.contact-contents {
	@media screen and (min-width: 768px) {
		display: flex;
	}
}

.contact-form {
	@media screen and (min-width: 768px) {
		max-width: 60%;
		min-width: 400px;
	}
}

.contact-item {
	display: flex;

	&+& {
		margin-top: 8px;
	}
}

.contact-icon {
	margin-right: 10px;
	width: 24px;

	svg {
		display: block;
		fill: $secondary;
	}
}

.contact-divider {
	&+& {
		border-top: 1px solid;
		border-top-color: $gray;
		margin-top: $unit * 2;
		padding-top: $unit * 2;

		@media screen and (min-width: 768px) {
			border-left: 1px solid;
			border-left-color: $gray;
			border-top: none;
			margin-left: $unit * 3;
			margin-top: 0;
			padding-left: $unit * 3;
			padding-top: 0;
		}
	}
}

.input-item {
	display: block;
}

.input-group {
	&+& {
		margin-top: $unit;
	}
}

.input-set {
	align-items: center;
	display: flex;
}

.input {
	border: 2px solid;
	border-color: $darkest;
	border-radius: $radius;
	display: flex;
	padding: 10px $unit;
	width: 100%;

	&:focus,
	&:hover,
	&:active {
		border-color: $primary;
		background-color: $lightest;
	}
}

.input:not(textarea) {
	height: 50px;
}

.textarea {
	resize: none;
}

.input-controls {
	margin-top: $unit * 2;
}

.form-note {
	.form-btn+& {
		margin-left: $unit;
	}
}

.quote {
	border-radius: $radius;
	font-size: 18px;
	line-height: 1.25;
	padding: ($unit * 1.5) ($unit * 2);
	position: relative;

	@media screen and (min-width: 768px) {
		font-size: 24px;
	}

	@media screen and (min-width: 1200px) {
		font-size: 36px;
	}
}

.quote-icon {
	border-radius: 100%;
	box-shadow: 0 0 10px rgba(0,0,0,.5);
	align-items: center;
	justify-content: center;
	display: flex;
	position: absolute;
	width: 36px;
	height: 36px;
	left: 0;
	font-style: normal;
	transform: translateX(-50%);
	top: $unit;

	svg {
		display: block;
		fill: $secondary;
		width: 80%;
	}
}

.link {
	color: $primaryDark;
}

.list-disc {
	list-style-type: disc;
	padding-left: 20px;
}

.screen-reader-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.post {
    max-width: 800px;
}

.post-info {
    color: $primary;
    font-size: 14px;
    margin-bottom: $unit * 3;
}

.wp-block-embed {
    margin: 0 0 1em;
    overflow-wrap: break-word;
}

.wp-block-embed__wrapper {
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-top: 56.25%; //16:9 aspect ratio
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;

        max-width: 100%;
        border-width: 0;
    }
}

.text-bold {
	font-weight: 700;
}

.wp-block-image {
    img {
        max-width: 100%;

        // override some image attrs
        height: inherit !important;
        width: inherit !important;
    }
}

.title-underline {
	&:before {
		border-bottom: 6px solid $primary;
		bottom: -$unit/2;
		content: "";
		height: 2px;
		position: absolute;
		width: calc(100% - ($unit * 2));

		@media screen and (min-width: 768px) {
			width: calc(100% - ($unit * 3));
		}

		@media screen and (min-width: 960px) {
			width: calc(100% - ($unit * 5));
		}
	}
}

.pipe {
	animation: blink 700ms linear infinite alternate;
}

@keyframes blink {
	0% {opacity: 1;}
    40% {opacity: 1;}
    60% {opacity: 0;}
    100% {opacity: 0;}
}
