$primary: #8E61C7;
$primaryLight: #bb9be3;
$primaryDark: #5f3694;

$secondary: #efca5e;
$secondaryLight: #ecdfb9;

$success: #6cc049;
$warning: #f2862d;
$alert: #e44242;

$white: #fff;
$lightest: #f5f5f5;
$light: #ddd;
$gray: #bbb;
$dark: #555;
$darkest: #2b2b2b;
$black: #000;

$fontHeading: 'Red Hat Display', 'Helvetica Neue', sans-serif;
$fontBody: 'Spectral', serif;

$radius: 5px;

$unit: 1rem;
